import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const FourthSectionBackground = styled.div`
  min-height: 308px;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
`

const Section4Col = styled(Col)`
   font: 28px/36px Roboto;
   text-align:center;
   margin-bottom:50px;
   @media(max-width:767px){
    margin-bottom:10px;
   }
`

const FAHeart = styled(FontAwesomeIcon)`
  font-size:1.5rem;
  color: ${props => props.theme.colors.secondary_green};
`

const ItemTitle = styled.div`
  font: 28px/32px Abril Fatface;
  color: ${props => props.theme.colors.secondary_green};
  margin-bottom:15px;
  @media(max-width:320px){
    font: 25px/30px Abril Fatface;
  }
`

const ItemContent = styled.div`
  font: 20px/28px Roboto;
`

export default function SignUpforMentorshipSection4() {
  return (
  <FourthSectionBackground>
    <Container>
      <Row css={css`@media(max-width:767px){padding-top:30px;padding-bottom:40px;}`}>
          <Section4Col md="4" xs="12">
            <FAHeart icon={faHeart} /> 
            <ItemTitle>&#62;93% of mentees</ItemTitle>
            <ItemContent>promotes WomenWorks to their friends</ItemContent>
          </Section4Col>
          <Section4Col md="4" xs="12">
            <FAHeart icon={faHeart} /> 
            <ItemTitle>100% of mentees</ItemTitle>
            <ItemContent>said their mentors’ advice were actionable</ItemContent>
          </Section4Col>
          <Section4Col md="4" xs="12">
            <FAHeart icon={faHeart} /> 
            <ItemTitle>&#62;90% of mentees are</ItemTitle>
            <ItemContent>significantly disappointed if they are not able to continue using WomenWorks service. Don’t worry, we won’t stop! <span role="img" aria-label="kissing_heart">😘</span></ItemContent>
          </Section4Col>
      </Row>
    </Container>
  </FourthSectionBackground>
  )
}